import React, { useState, useEffect } from 'react';
import { Modal, FormGroup, Button, Form, Input, Card, CardBody, CardHeader, CardFooter, Col, Row, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import reactCSS from 'reactcss';
import { useAlert } from 'react-alert'
import './login.css';
// import {COLORS} from '../../utils//color.js'
import general_fetch from '../utils/fetch';
import {useHistory } from 'react-router-dom';
import GoogleAuth from '../GoogleAuth'
import Country_list from '../utils/country';
import {Tooltip} from 'antd'

let login_method = '';

const styles =  reactCSS({
    'default': {
        main_div:{
            // background: 'url("/resources/images/site_image.jpeg") 50% fixed',
            height: '100vh',
            width : '100%',
            position: 'relative',
            backgroundSize: 'cover',
            overflow: 'hidden',
            backgroundColor:'white'

        },
        layer: {
            width: '100%',
            height: '100vh',
            position: 'absolute',
            left: '0',
            top: '0',
            // background: 'white'
        },
        modal_size_sm:{
            maxWidth:'350px !important'
        }
    },
});

const Store_options_modal = ({open, handle_close, all_store_options, onclick_store, set_modal_type, set_account_type, user_id}) => {

    // let professional_stores;
    // let business_stores;

    const [professional_stores, set_professional_stores] = useState([]);
    const [business_stores, set_business_stores] = useState([]);
    const [master_of_a_business_store, set_master_of_a_business_store] = useState(false);
    
    useEffect(() => {
        if(all_store_options && all_store_options.length){
            set_professional_stores(all_store_options.filter(x => x.type == 'professional'));
            set_business_stores(all_store_options.filter(x => x.type == 'business'));
        }
        // console.log("stores", professional_stores, business_stores)
    }, [all_store_options]);

    const onclick_handleclose = () => {
        handle_close()
        set_master_of_a_business_store(false)
    }

    const onclick_single_store = (selected_store) => {
        onclick_store(selected_store)
        onclick_handleclose()
    }

    useEffect(() => {
        console.log("stores", professional_stores, business_stores)
        if(business_stores && business_stores.length){
            business_stores.map((item) => {
                if(item.user_id === item.master_admin_id){
                    set_master_of_a_business_store(true)
                    return
                }
            })
        }
    }, [business_stores]);

    return(
        <Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={onclick_handleclose}>
            <Card className='store_options_card'>
                <CardHeader style={{display:'flex', alignItems:'center', justifyContent:'space-between', fontSize:'16px', fontWeight:600}}>
                    <div>Sign-in with Infurnia</div>
                    <i style={{cursor:'pointer'}} onClick={onclick_handleclose} className='fa fa-times'/>
                </CardHeader> 
                <CardBody style={{padding:0}}> 
                    <div style={{padding:'20px', fontSize:'16px', fontWeight:600, textAlign:'center'}}>
                        <div>Choose Organization</div>
                    </div>
                    <div style={{padding:'20px 20px 10px 20px', fontSize:'14px', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                        <div>Org Accounts</div>
                        <Tooltip title={master_of_a_business_store ? 'You cannot create an Org account because you are the master of an existing Org account' : ''} placement="bottom" mouseEnterDelay={0.5}>
                            <div style={{cursor:'pointer', color:master_of_a_business_store?'#BEBEBE':''}} onClick={master_of_a_business_store ? null : () => {set_account_type('business'); set_modal_type('store_setup'); onclick_handleclose()}}> + Create Org Account</div>
                        </Tooltip>
                    </div>
                    <div className='all_store_options_div' id="all_store_options_div">
                        {
                            business_stores && business_stores.length ? business_stores.map((item, idx)=>
                                <div id={item.id} title={item.name} className='single_store' onClick={()=>onclick_single_store(item)} key={idx} style={{cursor:'pointer'}}>
                                    <i className='fa fa-user-circle-o single_store_icon'/>
                                    <div className='single_store_text'>{item.name} <span style={{color:'#BEBEBE'}}>{item.user_id === item.master_admin_id ? '(Master)' : ''}</span></div>
                                </div>
                            )
                            :<div className='flex_center' style={{padding:'0px 20px 20px 20px', color:'gray'}}>No Organizations to list</div>
                        }
                    </div>
                    <div style={{padding:'20px 20px 10px 20px', fontSize:'14px', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                        <div>Professional Accounts</div>
                        <Tooltip title={professional_stores&&professional_stores.length ? 'You cannot create a professional account because you are part of an existing professional account' : ''} placement="bottom" mouseEnterDelay={0.5}>
                            <div style={{cursor:'pointer', color:professional_stores&&professional_stores.length?'#BEBEBE':''}} onClick={professional_stores && professional_stores.length ? null : () => {set_account_type('professional'); set_modal_type('store_setup'); onclick_handleclose()}}> + Create Professional Account</div>
                        </Tooltip>
                    </div>
                    <div className='all_store_options_div'>
                        {
                            professional_stores && professional_stores.length?professional_stores.map((item, idx)=>
                                <div id={item.id} title={item.name} className='single_store' onClick={()=>onclick_single_store(item)} key={idx} style={{cursor:'pointer'}}>
                                    <i className='fa fa-user-circle-o single_store_icon'/>
                                    <div className='single_store_text'>{item.name}</div>
                                </div>
                            )
                            :<div className='flex_center' style={{padding:'0px 20px 20px 20px', color:'gray'}}>No Professional Accounts to list</div>
                        }
                    </div>
                </CardBody>
                {/* <CardFooter onClick={() => {set_modal_type('new_to_inf'); onclick_handleclose()}} style={{cursor:'pointer'}}>
                    Create New Organization
                </CardFooter> */}
            </Card>
        </Modal>
    )
}


const Forgot_password_modal = ({ open, handleclose, set_page_loader}) => {

    const alert = useAlert()
    const [email_id_for_link, set_email_id_for_link] = useState('')
    const [error_text, set_error_text] = useState('')

    const onclick_handleclose = () => {
        set_email_id_for_link('');
        handleclose();
    }

    const validate_email = () => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email_id_for_link);
    }

    const clean_Fp_data = () =>{
        set_email_id_for_link('')
    }

    const onclick_send_reset_link = async() => {
        if(!validate_email()){
            set_error_text('Incorrect Email Id');
            alert.error('Please enter correct Email Id.');
        }else{
            try{
                var body={email_or_username:email_id_for_link}
                set_page_loader({
                    show: true,
                    text: 'Sending password reset link...'
                });
                var resp = await general_fetch({url:'/user/init_forgot_password', body, return_response_code:true});
                set_page_loader({
                    show: false,
                    text: ''
                });
                if(resp && resp.response_code===1){
                    window.analytics.track("auto", {
                        "email": email_id_for_link,
                        "category": "reset_password"
                    })
                    alert.success('Reset password link sent successfully.')
                    onclick_handleclose()
                }else if(resp && resp.code){
                    if(resp.code == "USER_NOT_SIGNED_UP"){
                        alert.error('Email not registered. Please Sign up.')
                        onclick_handleclose()
                    }
                }
                clean_Fp_data();
        }catch(err){
            console.log(err)
            alert.error('Request Failed!')
            clean_Fp_data()
            set_page_loader({
                show: false,
                text: ''
            });
        }
        }

    }
    
    return(
        <Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={onclick_handleclose}>
            {/* <Card style={{}}>
                <CardHeader>
                    <Row>
                        <Col>
                            Find your Account
                        </Col>
                        <Col style={{textAlign:'right'}}>
                            <span style={{cursor:'pointer'}} onClick={onclick_handleclose}>×</span>
                        </Col>
                    </Row>
                </CardHeader> 
                <CardBody>           
                    <Row style={{paddingBottom:'5px'}}>
                        <Col className="text-muted">
                            <span className='sign_up_text_color'>Please enter your Email Id</span>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom:'30px'}}>
                        <Col>
                            <Input value={email_id_for_link} onChange={(e) => set_email_id_for_link(e.target.value)} style={{fontSize:'12px'}} placeholder='Email'  type='text'/>
                        </Col>
                    </Row> */}
                    {/* <Row style={{display:error_text?'block':'none',paddingBottom:'30px'}}>
                        <Col>
                            <span>{error_text}</span>
                        </Col>
                    </Row> */}
                {/* </CardBody> 
                <CardFooter>   
                    <Row>    
                        <Col style={{ textAlign:'right'}}>
                            <Button onClick={onclick_send_reset_link} className='blue_button' style={{ whiteSpace:'nowrap'}}>Send Reset Link</Button>
                        </Col>
                    </Row>
                </CardFooter>
                
            </Card> */}
            <ModalHeader style={{ backgroundColor:'white', color:'black', height:'60px', boxShadow:'0px 1px 12px rgba(0, 0, 0, 0.1)'}} toggle={onclick_handleclose}>Find your Account</ModalHeader>
            <ModalBody style={{padding:'40px'}}>
                <div className='light_text' style={{marginBottom:'10px', fontSize:'14px'}}>Please enter your Email Id</div>
                <Input value={email_id_for_link} onChange={(e) => set_email_id_for_link(e.target.value)} style={{fontSize:'16px', height:'48px'}} placeholder='Email'  type='text'/>
            </ModalBody>
            <ModalFooter style={{border:'0', marginBottom:'32px', marginRight:'16px'}}>
                <Button onClick={onclick_send_reset_link} style={{ whiteSpace:'nowrap', backgroundColor:'#4597F7', border:'0', height:'40px', fontSize:'16px', padding:'8px 12px'}}>Send Reset Link</Button>
            </ModalFooter>
        </Modal>
    )    
}

const NoDesignerAccessModal = ({open, handle_close, selected_store, logout}) => {
    const alert = useAlert()
    const set_store_fn = async() => {
        try{
            var resp_onclick_store = await general_fetch({url:'/user/set_active_store', body:{selected_store_id:selected_store.id}})
            alert.success('Successfully signed into ' + selected_store.name)
            console.log('')
            window.location.href = global.config.admin_path
        }catch(err){
            alert.error('Error in Logging into selected store')
            console.log(err)
        }
    }

    useEffect(() => {   
        console.log('NoDesignerAccessModal')
    }, [open]);

    return(
        <Modal isOpen={open} size='md' onClose={handle_close} style={{height:'150px', width:'360px', marginTop:'17%'}}>
            <Card>
                <div style = {{backgroundColor:'#F5F6F8', overFlow:'auto', padding:'5%', fontSize:'15px'}}> 
                    <div style = {{padding:'16px 2px 10px 16px'}}>You don't have access to design portal in <span>"{selected_store ? selected_store.name : ''}"</span> organization.</div>
                    <div style = {{padding:'4px 0px 10px 16px', color:'#1588E0', textDecoration:'underline',}}><span onClick={() => set_store_fn()} style={{cursor:'pointer'}}>Go to Admin Portal</span></div>
                    <div style={{display:'flex', padding:'10px 20px', justifyContent:'center'}}>
                        {/* <Button className='white_button' type="button" onClick={() => {handle_close()}}>Cancel</Button> */}
                        <Button className='white_button' type="button" onClick={() => {handle_close(); logout()}}>Close</Button>
                    </div>
                </div>
            </Card>
        </Modal>
    )
}


const Sign_up = ({org_key, init, set_user_details, init_needed, set_init_needed, set_page_loader, org_key_logo, org_key_id, org_key_name, login_modal_type, set_login_modal_type}) => {

    const alert = useAlert()
    const history = useHistory();
    var SHA512 = require('js-sha512')
    
    const [login_state, set_login_state] = useState('signin')
    //const [target, set_target] = useState('')
    const [target_type, set_target_type] = useState('')
    const [target_path, set_target_path] = useState('')

    const [ email, set_email ] = useState("");
    const [ password, set_password] = useState("");

    const [ IP_city, set_IP_city ] = useState("");
    const [ IP_country, set_IP_country] = useState("");
    const [ market, set_market] = useState("");
    const [ store_country, set_store_country] = useState("");

    // const [ login_modal_type, set_login_modal_type ] = useState('normal')


    const [ loaded, set_loaded ] = useState(false);

    const [ sign_up_email, set_sign_up_email ] = useState("");
    const [ account_type, set_account_type] = useState(""); //todo
    const [ store_name, set_store_name] = useState('');

    const [ create_password, set_create_password ] = useState("");
    const [ confirm_password, set_confirm_password] = useState("");
    
    const [ confirm_otp, set_confirm_otp] = useState("");
    const [ terms_and_conditions, set_terms_and_conditions] = useState(true); // TODO

    const [show_forgot_password_modal, set_show_forgot_password_modal] = useState(false)

    const [show_store_options_modal, set_show_store_options_modal] = useState(false)
    const [all_store_options, set_all_store_options] = useState([])

    const [ google_social_login_script_loaded, set_google_social_login_script_loaded ] = useState(false);
    const [ google, set_google ] = useState(null);

    const [is_user_loaded, set_is_user_loaded] = useState(false);
    const [user_id, set_user_id] = useState('');

    const [show_no_designer_access_modal, set_show_no_designer_access_modal] = useState();
    const [selected_store, set_selected_store] = useState();

    const [show_password_normal, set_show_password_normal] = useState(false);

    // const [login_method, set_login_method] = useState('');

    let param = new URLSearchParams(document.location.search)
    let target = param.get("target")
    let path = param.get("path")
    useEffect(() => {
        console.log("store country", store_country)
    }, [store_country]);

    useEffect(() => {
        console.log('modal window', login_modal_type)
        window.analytics.page("auto", {
            "page": login_modal_type
        })
    }, [login_modal_type]);

    // useEffect(() => {
    //     console.log("login method", login_method)
    // }, [login_method]);
    
    useEffect(() => {
        // login_init();
        console.log("target", target)

        if(target === "social_signup"){
            set_login_modal_type("already_signed_user")
        }else if(target === "admin"){
            set_target_type('admin')
            set_target_path(global.config.admin_path.replace('com', window.location.origin.split('.')[2]) + (path ? path : ''))
            console.log("target path print3")

        }else if(target === "sales"){
            set_target_type('sales')
            set_target_path(global.config.sales_path)
            console.log("target path print4")

        }else if(target === "affiliate"){
            set_target_type('affiliate')
            set_target_path(global.config.affiliate_path)
            console.log("target path print2")

        }else{
            set_target_type('designer');
            set_target_path(global.config.design_path.replace('com', window.location.origin.split('.')[2]) + (path ? path : ''));
            console.log("target path print1", global.config.design_path)

        }

        if(window.location.href.includes('e=multilogin')){
            alert.error("The same account was signed in on another device.")
        }
    },[target]);

    useEffect(() => {
        console.log("target path print", target_path)
        if(target_path){
            login_init();
        }
    }, [target_path]);

    const load_google_auth_script = async src => {
        return new Promise((resolve, reject) => {
            if (document.querySelector(`script[src="${src}"]`)) return resolve()
            const script = document.createElement('script')
            script.src = src
            script.async = true;
            script.defer = true;
            script.onload = () => resolve(true)
            script.onerror = (err) => reject(err)
            document.body.appendChild(script)
        });
    }

    const initialize_social_login = async () => {
        await load_google_auth_script('https://accounts.google.com/gsi/client');
        // console.log("inside 54 google social login script loaded", window.google)
        set_google_social_login_script_loaded(true);
        set_google(window.google);
    }

    const route_finder = async() => {
        console.log('workspaces----------------------')
        try{
            var checking_response = await general_fetch({url:'/user/get_details', data_field:'user'});
            // console.log("User details loaded 2", checking_response)
            if(checking_response){
                set_user_details(checking_response.user_details)
                // console.log('user details loaded done')
            }
            if(checking_response['user_details'] && checking_response['user_details']['orphan'] == 1){
                set_login_modal_type('new_to_inf')
                return
            }
            if(checking_response && checking_response != "error" && checking_response != "unauthorized"){
                set_is_user_loaded(true)
                set_user_id(checking_response['user_details']['id'])
                if(org_key && org_key_id != ""){
                    var resp_store_details = await general_fetch({url:'/user/get_attached_stores'});
                    if(resp_store_details && resp_store_details.length>0){
                        var store_find = resp_store_details.find(o => o.id == org_key_id)
                        if(store_find){
                            var resp_onclick_store = await general_fetch({url:'/user/set_active_store', body:{selected_store_id:org_key_id}})
                            alert.success('Successfully signed into ' + org_key_name);
                            redirect_to_target_final();
                        }else{
                            alert.error("You don't have an account in " + org_key_name)
                            logout();
                        }
                    }
                }else{
                    set_page_loader({show:true, text: "Fetching Organization Info..."})
                    var resp_store_details = await general_fetch({url:'/user/get_attached_stores'})
                    if(resp_store_details && resp_store_details.length>0){
                        console.log('get_attached_stores', resp_store_details)
                        set_all_store_options(resp_store_details)
                    }
                    set_page_loader({show:false, text: "Please Wait..."})

                    // else{
                    //     redirect_to_target_final();   
                    // }
                }
            }else{
                redirect_to_target_final();
            }
        }catch(err){
            alert.error('Error in fetching details for Stores')
            console.log(err)
        }
    } 

    const login_init = async () => {
        try{
            // console.log("inside login init try")
            //TODO response needs to be as per core 
            set_page_loader({
                show: true,
                text: 'Loading'
            });
            // console.log("User details loaded 1")
            var response = await general_fetch({url:'/user/get_details', return_raw_response:true});
            set_page_loader({
                show: false,
                text: 'User Details Found. Redirecting...'
            });
            
            console.log("inside login init try response", response)
            if(response['response_code'] == 1 && response['user'] && response['user']['user_details'])set_user_details(response['user']['user_details'])
            //TODO handle orphan case
            if(response['response_code'] == 1 && response['user'] && response['user']['user_details'] && response['user']['user_details']['orphan'] != 1 && response['user']['user_details']['id'] != 6){
                console.log("is redirecting to final ?")
                await redirect_to_target_final();
            } else if(response['response_code'] == 1 && response['user'] && response['user']['user_details'] && response['user']['user_details']['orphan'] == 1){
                console.log("is redirecting to final 1?")
                set_loaded(true);
                set_is_user_loaded(true)
                set_login_modal_type('new_to_inf')
            }else{
                set_loaded(true);
            }

            return response;
        }catch(err){
            console.log(err);
            return "error";
            // return Promise.reject(err);
        }
    }

    const redirect_to_target_final = async () => {
        let resp_user_details = await general_fetch({url:'/user/get_details', data_field:'user'});
            window.analytics.track("auto", {
                "user_id": resp_user_details.user_details.id,
                "email": resp_user_details.user_details.email,
                "method": login_method,
            })
        console.log("inside redirect to final")

        if(target_type==='affiliate' || target_type==='sales'){
            console.log("inside redirect to final if block")
            window.location.href=target_path
        }else if(target_type==='admin'){
            if(!window.location.origin.includes("infurnia.com")){
                let url = target_path;
                url = url.replace('infurnia', org_key)
				window.location.href=url
            }else{
                window.location.href=target_path
            }
        }
        else{
            //TODO 
            console.log("inside redirect to final else block")
            // var resp = await trigger_init();
            // console.log("resp redirect", resp)
            // if(!resp){
                let url = target_path;
                if(!window.location.origin.includes("infurnia.com")){
                    url = url.replace('infurnia', org_key)
                }else{
                    let t = (org_key?(url = url + "/" + org_key):null);
                }
                // console.log("redirect url t final",url,t)
                // history.push(url)
                // if(target_path){
                window.location.href=url
                // }
            // }else{
            //     console.log("redirect",resp)
            // }
        }
    }

    useEffect(() => {
        if(all_store_options && all_store_options.length){
            set_show_store_options_modal(true)
        }
    },[all_store_options])

    const onclick_store = async(selected_store) => {
        try{
            console.log("selected store" , selected_store)
            set_selected_store(selected_store)
            if(!(selected_store.roles.includes("designer")) && target_type != 'admin' && target_type != 'affiliate'){
                console.log("selected store 1" , selected_store)
                set_show_no_designer_access_modal(true)
                return
            }
            let resp_onclick_store = await general_fetch({url:'/user/set_active_store', body:{selected_store_id:selected_store.id}})
            let resp_user_details = await general_fetch({url:'/user/get_details', data_field:'user'});
            window.analytics.track("auto", {
                "user_id": resp_user_details.user_details.id,
                "email": resp_user_details.user_details.email,
                "method": login_method,
            })

            alert.success('Successfully signed into ' + selected_store.name)
            if(target_type==='admin'|| target_type==='affiliate'){
                window.location.href=target_path
            }
            else{
                //TODO
                // var resp = await trigger_init();
                // console.log("redirect resp",resp)
                // if(!resp){
                    var url = target_path;
                    let t = (selected_store.white_labelling?(url = url + "/" + selected_store.white_labelling_url):null);
                    // let t = (org_key?(url = "/"+org_key + url):null);
                    console.log("redirect url t",url,t)
                    // history.push(url)
                    window.location.href=url

                // }else{
                //     console.log("redirect",resp)
                // }
            }
        }catch(err){
            alert.error('Error in Logging into selected store')
            console.log(err)
        }
    }

    const close_store_options_modal = () => {
        set_show_store_options_modal(false)
        set_all_store_options([])
    }

    useEffect(()=> {
        // if (getUrlVars()['target'] == 'admin') {
        //     set_target_type('admin');
        //     set_target_path(global.config.admin_path);
        // }
        // else if (getUrlVars()['target'] == 'affiliate') {
        //     set_target_type('affiliate');
        //     set_target_path(global.config.affiliate_path);
        //     // $('#terms_and_conditions_link').attr('href', 'https://www.infurnia.com/affiliate-terms-and-conditions/');
        //     // $('#account_type_div').hide()
        // }
        // else {
        //     set_target_type('designer');
        //     set_target_path('/');

        //     if (getUrlVars()['account_type'] == 'professional') {
        //         set_account_type('professional')
        //         go_to_signup_choice()
        //     }
        //     else if (getUrlVars()['account_type'] == 'business') {
        //         set_account_type('business')
        //         go_to_signup_choice()
        //     }
        // } 
        console.log("constructor")
        initialize_social_login();
        initialize_get_ip_details()
        // window.onSignIn = onSignIn


    },[])

    useEffect(() => {
        console.log('org key press', org_key)
    }, [org_key]);

   

    // {
    //     "ip": "49.207.198.175",
    //     "hostname": "broadband.actcorp.in",
    //     "city": "Bengaluru",
    //     "region": "Karnataka",
    //     "country": "IN",
    //     "loc": "12.9719,77.5937",
    //     "org": "AS24309 Atria Convergence Technologies Pvt. Ltd. Broadband Internet Service Provider INDIA",
    //     "postal": "560002",
    //     "timezone": "Asia/Kolkata",
    //     "readme": "https://ipinfo.io/missingauth"
    //   }%  

    const initialize_get_ip_details = async () =>{
        try{
            var response = await general_fetch({full_url:"https://ipapi.co/json/", return_raw_response:true, method:'get',credentials:'omit'})
            console.log(response);
            response && set_IP_country(response.country);
            response && set_IP_city(response.city);
            response && set_store_country(response.country);
            if (response.country === "IN") {
                set_market('india')
            }
            else {
                set_market('international')
            }
        }catch(err){
            // console.log(err)
            set_IP_city('')
            set_IP_country('IN')
            set_market('india')
        }
    }

    var getUrlVars = function() {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
        });
        return vars;
    }

    const logout = () => {
        general_fetch({url:'/user/logout'})
        .then(() => {
            if(window.socket){
                window.socket.disconnect();
            }
            set_init_needed(true);
            var url = "/";
            let tt = (org_key?(url = "/"+org_key + url):null);
            //possibility of error
            let t = (target_type=='admin'?url += "?target=admin": null);
            history.push(url)
        })
    }

    const go_to_signup_choice = () => {
        set_login_state('signup');
    
        // $("#login_section").slideUp('slow',function(){
        //     $("#signup_section").slideDown('slow');        
        //   });
        // send_analytics_event('click', 'signup', 'signup_entry', 0)
    }

    const trigger_init = async () => {
        set_page_loader({
            show: true,
            text: 'Loading...'
        });
        set_init_needed(false);
        var resp = await init();
        set_page_loader({
            show: false,
            text: ''
        });

        return resp;
    }


    const enter_handle_login = (e) => {
        if(e.key==='Enter'){
            login_call_fn()
        } 
    }

    const login_call_fn = () => {
        if(email && password){
            set_page_loader({
                show: true,
                text: 'Loading...'
            });

            setTimeout(() => {
                // window.Promisify(window.Module.login(email,password))
                general_fetch({url:'/user/login', body:{username:email, password:SHA512(password)}})
                .then(response => {
                    if(response != "error"){
                        // init().then(() => {
                            // set_login_method("email")
                            login_method = "email";
                            setTimeout(() => {
                                set_page_loader({
                                    show: false,
                                    text: ''
                                });
                                route_finder()
                            }, 1000);
                        // });    
                    }else{
                        return Promise.reject();
                    }
                })
                .catch(err=>{
                    alert.error('Incorrect Email or Password')
                    set_page_loader({
                        show: false,
                        text: ''
                    });
                });
            }, 0);
        }else{
            alert.error('Please enter correct Email Id and Password.')
        }
    }

    const login_pop_up = async (social_site) => {
        var w = 550;
        var h = 600;
        var url = global.config.server_path+'/'+social_site;
        var title = 'Google Login';
     
        // Fixes dual-screen position
        var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
        var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;
     
        var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;
     
        var left = ((width / 2) - (w / 2)) + dualScreenLeft;
        var top = ((height / 2) - (h / 2)) + dualScreenTop;
        var win = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
        // Puts focus on the win
        if (window.focus) {
            win.focus();
        }

        var timer = setInterval(async() => {
            if(win && win.closed) {
                win = undefined;
               // clearInterval(timer);
               try{
                    route_finder()
               }catch(err){
                   console.log(err)
                   alert.error("Request Failed. Try Again.")
                   set_page_loader({
                    show: false,
                    text: ''
                });
               }                
            }
        }, 100);
     
        return false;               
    }

    const delete_all_data_taken_for_cerate_store = () =>{
        set_IP_city("");
        set_IP_country("");
        set_market("");
        set_login_modal_type('normal')
        set_sign_up_email("");
        set_account_type("professional");
        set_store_name('');
        set_target_type('');
        set_target_type('');

        set_create_password("");
        set_confirm_password("");
        set_confirm_otp("");
        set_terms_and_conditions(false);

        // set_login_method('')
        login_method = '';

    }

    const create_store = async () => {
        try{
            var sha_password = SHA512(create_password);
            var store_data = { username:'', contact:'', website:'',otp:confirm_otp, name: store_name, store_address:IP_city + "," + IP_country, email:sign_up_email, password : sha_password, market:market, type:account_type, country:store_country}
            set_page_loader({
                show: true,
                text: 'Creating New Store...'
            });
            var resp = await general_fetch({url:'/store/add', body:store_data, return_response_code:true})
            set_page_loader({
                show: false,
                text: ''
            });
            if(resp && resp.response_code === 1) {
                alert.success(account_type==='business'?'Business':'Professional' + " account successfully created")
                let resp_user_details = await general_fetch({url:'/user/get_details', data_field:'user'});
                window.analytics.track("auto", {
                    "user_id": resp_user_details.user_details.id,
                    "email": resp_user_details.user_details.email,
                    "method": login_method,
                    "type": account_type
                })
                delete_all_data_taken_for_cerate_store() ;
                // await trigger_init();
                var url = target_path;
                window.location.href = url
                // let t = (org_key?(url = "/"+org_key + url):null);
                // history.push(url)
            }
            // else if (resp && resp.response_code === 0) {
            //     //loader
                // alert.error("Signup Failed. Try Again.")
            //     delete_all_data_taken_for_cerate_store() ;
            //     var url = "/login";
            //     (org_key?(url = "/"+org_key + url):null);
            //     history.push(url)
            // }
            // else if (resp &&resp.response_code === -2) {
            //     //loader
            //     alert.error("OTP : " + sign_up_email)
            //     delete_all_data_taken_for_cerate_store() ;
            //     var url = "/login";
            //     (org_key?(url = "/"+org_key + url):null);
            //     history.push(url)
            // }
            else if (resp &&resp.code) {
                //loader
                if(resp.code == "OTP_INVALID"){
                    alert.error("Incorrect OTP")
                }
                else if(resp.code == "USER_ALREADY_SIGNED_UP"){
                    alert.error("User already signed up")
                }
                else if(resp.code == "SIGNUP_LIMIT_REACHED"){
                    alert.error("A Store already exists for given account")
                }
            }
            else {
                //loader todo
                alert.error("Signup Failed. Try Again.");
                delete_all_data_taken_for_cerate_store() ;
                set_init_needed(true);
                var url = "/";
                let t = (org_key?(url = "/"+org_key + url):null);
                history.push(url)
            }
        }catch(err){
            console.log(err)
            alert.error("Signup Failed. Try Again.");
            delete_all_data_taken_for_cerate_store();
            set_page_loader({
                show: false,
                text: ''
            });
            var url = "/"; // todo
            set_init_needed(true);
            let t = (org_key?(url = "/"+org_key + url):null); 
            history.push(url)
        }   
    }

    const create_affiliate = async() => {
        try{
            var affiliate_data = { email:sign_up_email, password : SHA512(confirm_password), market:market}
            set_page_loader({
                show: true,
                text: 'Creating New Store...'
            });
            var resp = await general_fetch({url:'/referral/add_affiliate', body:affiliate_data});
            set_page_loader({
                show: false,
                text: ''
            });
            if(resp.response_code === 1) {
                //loader
                // alert.success("Affiliate account successfully created")
                //window.location = 'https://www.infurnia.com/affiliate-terms-and-conditions/';
                delete_all_data_taken_for_cerate_store() ;
                route_finder();
            }
            else {
                //loader
                // alert.error('Signup Failed. Try Again.');
            }
        }catch(err){
            console.log(err)
            // alert.error('Signup Failed. Try Again.');
            set_page_loader({
                show: false,
                text: ''
            });
        }  
        
    }

    const validate_email = (email_temp) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email_temp);
    }

    const validate_password = () => {
        if(create_password) {
            return true
        }
        return false
    }
    
    const validate_repassword=() => {
        if(create_password===confirm_password) {
            return true
        }
        return false
    }


    const send_otp_to_email = async () => {
        try{
            var body={email:sign_up_email, type:account_type}
            set_page_loader({
                show: true,
                text: 'Sending OTP...'
            });
            var resp = await general_fetch({url:'/store/generate_signup_otp', body, return_response_code:true});
            set_page_loader({
                show: false,
                text: 'Sent'
            });
            if(resp && resp.response_code === 1) {
                set_login_modal_type('password')
                alert.success("Please check otp sent on your Email")
            }
            else if (resp && resp.code) {
                if(resp.code == "ALREADY_SIGNED_UP_AND_PASSWORD_SET"){
                    alert.error("User Already signed up. You can create new organization from login")
                }else if(resp.code == "PASSWORD_NOT_SET"){
                    alert.error("Given Email is already a part of an Organization. To set password plese refer to the email sent to you or you can reset your password by visiting login.infurnia.com")
                }
            }
        }catch(err){
            console.log(err)
            alert.error("Request Failed!")
            set_page_loader({
                show: false,
                text: ''
            });
        }  
    }

    const onclick_proceed_for_otp = () =>{
        
        var valid_email = validate_email(sign_up_email);
    
        if (!valid_email) {
            alert.error("Please provide a valid Email id")
        }
        else if (!validate_password()) {
            alert.error("Please provide a valid password");    
        }
        else if (!validate_repassword()) {
            alert.error("Password mismatch");
        }
        else{     
            send_otp_to_email();   
        }
    }

    const user_validation_submit = () => {
        
        // if (!validate_password()) {
        //     // alert.error("Please provide a valid password");    
        // }
        // else if (!validate_repassword()) {
        //     // alert.error("Password mismatch");
        // }
        if (!terms_and_conditions) {
            // alert.error("Please agree to terms and conditions");
        }
        else if(!confirm_otp){
            alert.error('Please Enter OTP')
        }else {
            if (target_type==='affiliate') {
                create_affiliate()
            }
            else {
                create_store();
            }
        }        
    }

    const user_validation_submit_no_store = () => {
        
        if (!terms_and_conditions) {
            // alert.error("Please agree to terms and conditions");
        }
        else {
            if (target_type==='affiliate') {
                create_affiliate()
            }
            else {
                create_store();
            }
        }        
    }

    const is_valid_otp = async () => {
        try{ //todo
            let resp = await general_fetch({url:'/store/otp_verify', body:{email:sign_up_email, otp:confirm_otp}, data_field:'response_code'})
            // console.log("resp new api", resp)
            // set_login_method('email')
            login_method = "email"
            set_login_modal_type('store_setup')
        }catch{
            alert.error("Invalid OTP")
        }
    }

    const close_forgot_password_modal = () => {
        set_show_forgot_password_modal(false);
    }
    const open_forgot_password_modal = () => {
        set_show_forgot_password_modal(true);
    }

    const go_back_to_normal = () => {
        set_login_modal_type('normal')
        set_login_state('signin')
    }

    // const onSignIn = (googleUser) => {
    //     var profile = googleUser.getBasicProfile();
    //     console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
    //     console.log('Name: ' + profile.getName());
    //     console.log('Image URL: ' + profile.getImageUrl());
    //     console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
    // }
    
    const authorization_callback = async resp => {
        try {
            // if (resp.credential) {
            //     console.log('credential', resp.credential);
            // }
            let res = await general_fetch({ 
                url: '/social_login_google/stageadmin',
                body: {
                    credential: resp.credential,
                }
            });
            // set_login_method("google")
            login_method = "google"
            if(res && res['user'] && !res['user']['orphan']){
                route_finder()
                // window.analytics.track("auto", {
                //     "user_id": res['user'].id
                // })
            }
            // else if(res && res['user'] && !res['user']['orphan'] && login_state == 'signup'){
            //     //route_finder()
            //     var resp_store_details = await general_fetch({url:'/user/get_attached_stores'});
            //     let owned_stores = resp_store_details.filter(x => x['master_admin_id'] == res['user']['id'])
            //     if(owned_stores.filter(x => x.type == account_type).length){
            //         alert.error("A Store already exists for the given account")
            //     }else{
            //         set_login_modal_type('store_setup')
            //     }

            // }
            else if(res && res['user'] && res['user']['orphan'] == 1 && !account_type){
                set_is_user_loaded(true)
                set_login_modal_type('new_to_inf')
            }
            else if(res && res['user'] && res['user']['orphan'] == 1 && account_type){
                set_is_user_loaded(true)
                set_login_modal_type('store_setup')
            }
            // set_show_store_options_modal(true)
            console.log('social login response', res);
        } catch (err) {
            console.error(err);
        }
    }

    return !loaded ? "" : (
        <div style={styles.main_div}>
            {/* <div style={styles.layer}/> */}
            
            <Forgot_password_modal open={show_forgot_password_modal} handleclose={close_forgot_password_modal} set_page_loader={set_page_loader}/>
            <Store_options_modal open={show_store_options_modal} handle_close={close_store_options_modal} all_store_options={all_store_options} set_page_loader={set_page_loader} onclick_store={onclick_store} set_modal_type={set_login_modal_type} set_account_type={set_account_type} user_id={user_id}/>
            <NoDesignerAccessModal open={show_no_designer_access_modal} handle_close={() => set_show_no_designer_access_modal(false)} selected_store={selected_store} logout={logout}/>
            <div style={{display:'flex', width:'100%', height:'100%', overflow:'auto', flexWrap:'wrap'}}>
                <div className={login_modal_type==='normal'?'d-none d-sm-none d-md-none d-lg-flex col-lg-6':'d-none'} id='image_cross_fading' style={{overflow:'hidden', alignItems:'center', display:'', justifyContent:'center'}}>
                    {/* Experience Infurnia */}
                    <img style={{height:'100vh'}} src='../../infkitchen.jpg'></img>
                    <img style={{height:'100vh'}} src='../../infkitchen2.jpg'></img>
                    <img style={{height:'100vh'}} src='../../infliving.jpg'></img>
                </div>
                {/* <div className={login_modal_type==='new_to_inf'?'col-12 col-lg-1 d-flex':'d-none'} style={{overflow:'hidden', alignItems:'center', display:'flex', justifyContent:'center', flexDirection:'column', padding:'15px 30px'}}></div> */}
                <div className={login_modal_type==='new_to_inf'?'col-12 col-lg-4':'d-none'} style={{overflow:'hidden', display:'flex', justifyContent:'center', flexDirection:'row', alignItems:'center'}}>
                    {/* Experience Infurnia */}
                    {/* <img style={{height:'100vh'}} src='../../infkitchen.jpg'></img> */}
                    <div className={login_modal_type==='new_to_inf'?'col-2 d-flex':'d-none'} style={{overflow:'hidden', alignItems:'center', display:'flex', justifyContent:'center', flexDirection:'column', height:'100%'}}></div>
                    <div className={login_modal_type==='new_to_inf'?'col-10 d-flex':'d-none'} style={{overflow:'hidden', alignItems:'center', display:'flex', justifyContent:'center', flexDirection:'column', backgroundColor:'rgba(251, 251, 251, 1)', padding:'20px 40px', height:'100%'}}>
                        <div style={{fontSize:'xx-large', fontWeight:'bold', letterSpacing:'1px'}}>
                            Welcome To
                        </div>
                        <div style={{backgroundColor:'inherit', textAlign:'center', marginBottom:'40px'}}> <img  height='56px' src='../../infurnia.png'/> </div>    
                        <div style={{fontStyle:'italic', fontSize:'20px'}}>
                            Your all-in-one 
                        </div>
                        <div style={{fontStyle:'italic', marginBottom:'60px', fontSize:'20px'}}>
                            Architecture Software
                        </div>
                        <div className='light_text'>
                            Join thousands of architects worldwide using Infurnia to create designs in a seamless and collaborative way
                        </div>
                    </div>
                </div>
                <div className={login_modal_type==='with_email' || login_modal_type==='password'?'col-md-12 col-lg-6':'d-none'} style={{overflow:'auto', alignItems:'center', display:'flex', justifyContent:'center', flexDirection:'column', backgroundColor:'rgba(251, 251, 251, 1)', padding:'40px 80px'}}>
                    {/* Experience Infurnia */}
                    {/* <img style={{height:'100vh'}} src='../../infkitchen.jpg'></img> */}
                    <div className='inf_text_description marginBottom24'>
                        {account_type == 'business' ? "Studio" : "Professional"} License
                    </div>
                    <div style={{fontSize:'16px', marginBottom:'35px'}}>
                        {account_type == 'professional' ? <> As an individual designer you get continuous <b>free access</b> to the Infurnia Design App.</> : <>Create your Studio account &#38; start your <b>7 day free trial</b> of the Team Plan.</>} 
                    </div>
                    <div style={{backgroundColor:'inherit', textAlign:'center', marginBottom:'0px', width:'70%'}}> <img  height='300px' src= {account_type=='business' ? '../../Final Studio.png' : '../../Final Freelancer.png'}/> </div>    
                    <div style={{textAlign:'left', fontSize:'14px', letterSpacing:'1px'}} className='light_text marginBottom24'>
                        {
                            account_type == 'professional' ?
                            <ul>
                                <li>All the Design Features are included</li>
                                <li>Supports Architecture, interior &#38; modular kitchen.</li>
                                <li>limited Free HD Renders and low cost 4K</li>
                                <li>Export Design as .gltf</li>
                                <li>Generate required Production Drawings</li>
                            </ul>
                            :
                            <ul>
                                <li>All the Design Features are included</li>
                                <li>Supports Architecture, interior &#38; modular kitchen.</li>
                                <li>Export Design as .gltf</li>
                                <li>Production Drawings</li>
                                <li>100 HD &#40;or equivalent&#41; Renders /month per designer</li>
                                <li>Custom Catalog</li>
                                <li>BOM &#38; Price Quatation</li>
                                <li>Create your own design &#38; presentation templates</li>
                            </ul>
                        }
                    </div>
                    <div style={{fontSize:'13px'}} className='light_text'>
                        {
                            account_type == 'professional' ?
                                <>You cannot acess the Infurnia Admin app, or use team features like catalog management, price quotations, user account management, etc. to upgrade check out busniness licence.</>
                            :
                                <>Upgrade to Enterprise level to get Custom output generations, API Integrations, Usage &#38; Analytics reports, Custom Branding, Dedicated Account Manager, to upgrade please contact Sales</>
                        }
                        </div>
                </div>
                <div className={login_modal_type==='store_setup'?'col-12 col-lg-6':'d-none'} style={{overflow:'hidden', display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center'}}>
                        <div style={{fontSize:'32px', color:'rgba(197, 199, 207, 1)'}}>
                            Thank you for Choosing Us
                        </div>
                </div>
            <div className={login_modal_type==='normal'|| login_modal_type==='with_email' || login_modal_type==='password' || login_modal_type==='store_setup' ?'col-12 col-sm-12 col-md-12 col-lg-6':login_modal_type==='new_to_inf'?'col-12 col-lg-8':''} style={{ display:'flex', justifyContent:'center', alignItems:'center', /*position:"absolute", left:'calc((100% - 350px)/2)',top:'5%', height:'auto',*/ overflow:'auto', padding:'20px', flexDirection:login_modal_type==='normal'|| login_modal_type==='with_email' || login_modal_type==='password' || login_modal_type==='store_setup' ?'column':'row'}}>
                
                <div style={{backgroundColor:'inherit', textAlign:'center', marginBottom:'40px', display:login_modal_type==='normal'?'block':'none'}}><img  height='56px' src={org_key_logo} /></div>    
                <div style={{backgroundColor:'inherit', textAlign:'center', marginBottom:'40px', display:login_modal_type==='store_setup'?'block':'none', color:'rgba(103, 104, 120, 1)', fontSize:'24px'}}>Start by Creating your Organization</div>    
                {/* <Card> */}
                    
                    <div style = {{ display: login_modal_type==='normal'? 'block':'none', padding:'40px 80px', fontSize:'14px', border:'1px solid rgba(230, 233, 238, 1)', borderRadius:'14px', boxShadow:'8px 8px 20px 6px rgba(184, 191, 202, 0.14)', width:'425px', maxWidth:'99%'}}>
                        <Row>
                            <Col style={{marginBottom: '30px'}} >
                                <span style={{fontSize:'24px', color:'#435A6F', fontWeight:'500',lineHeight:'36px', letterSpacing:'1.5px'}}>{org_key?"Login to your Organization":"Login to Infurnia"}</span>
                            </Col>
                        </Row>        
                        <Row style={{height:'40px'}}>
                            <Col>
                                <GoogleAuth 
                                    target={target} 
                                    id='login1'
                                    visible={login_modal_type == 'normal' ? true : false}
                                    google={google}
                                    authorization_callback={authorization_callback}
                                />
                                {/* <div class="g-signin2" data-onsuccess="onSignIn"></div> */}
                                {/* <div className='btn_google g-signin2' data-onsuccess="onSignIn" style={{margin:'0px 0px', backgroundColor:'white', border:0, fontWeight:'bold', width:'60px', height:'60px',boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)', borderRadius:'6px', color:'red', fontSize:'28px', padding:'15px'}} type="button" onClick={() =>login_pop_up('google')} > <img className='googleLoginLogo' src='../../GoogleLogo.svg'></img> </div> */}
                            </Col>
                        </Row>
                        {/* {!org_key?<Row>
                            <Col>    
                                <Button className='btn_login btn_linkedin' style={{margin:'15px 0px', backgroundColor:'#007bb6', border:0 , fontWeight:'bold'}} type="button" onClick={() =>login_pop_up('linkedin')} >Sign in with Linkedin</Button>
                            </Col>
                        </Row>:''} */}
                        {/* <Row>
                            <Col style={{padding: '10px'}}>
                                <span style={{fontSize:'18px'}}>or</span>
                            </Col>
                        </Row> */}

                        <Row style={{marginTop:'15px'}}>
                            <Col xs={5} style={{}} >
                                <hr style={{height:'1px', borderWidth:'0px', backgroundColor:'#A7A8B3', color:'#A7A8B3'}}></hr>
                            </Col>
                            <Col xs={2} style={{ display:'flex', alignItems:'center', justifyContent:'center'}} >
                                <span style={{color:'#A7A8B3', display:'flex', alignItems:'center', justifyContent:'center'}}> OR </span>
                            </Col>
                            <Col xs={5} style={{}} >
                                <hr style={{height:'1px', borderWidth:'0px', backgroundColor:'#A7A8B3', color:'#A7A8B3'}}></hr>
                            </Col>
                        </Row>       

                        <Row>
                            <Col style={{margin:'15px 0px 24px 0px'}} >
                                <span style={{fontSize:'14px', color:'#A7A8B3', lineHeight:'24px'}}>{org_key?"Login with your org credentials":"Login with your Email ID"}</span>
                            </Col>
                        </Row>    
                            
                        <Form role="form">
                            <Row>
                                <Col>
                                    <FormGroup style={{marginBottom:"16px"}}>
                                        <Input id="login_username" className='input_login' placeholder="Email" type="text" value={email?email:''} onChange={(e) => set_email(e.target.value)} onKeyUp={(e) => {if(e.key == 'Enter'){document.getElementById('login_password').select()}}}/>
                                    </FormGroup>
                                </Col>  
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup id='marginBottom0' className='input_login' style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                        <Input id="login_password"  placeholder="Password" type= {show_password_normal ? "text" : "Password"} value={password?password:''}  onChange={(e) => set_password(e.target.value)} onKeyUp={(e)=>enter_handle_login(e)} />
                                        <i className={show_password_normal ? 'fa fa-eye-slash' : 'fa fa-eye' } onClick={() => {set_show_password_normal(!show_password_normal)}} style={{padding:'6px 12px', cursor:'pointer', color: '#C5C7CF'}}></i>
                                    </FormGroup>
                                </Col>  
                            </Row>
                            <Row>
                                <Col style={{textAlign:'left', fontSize:'14px', padding:'8px 12px', color:'#1588E0', marginBottom:'40px'}}>
                                    <span onClick={open_forgot_password_modal} style={{cursor:"pointer"}} className='forgot_password_hover'>Forgot Password?</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button  id="login_submit_normal" type="button" className=' btn_success' style={{ background:'linear-gradient(0deg, #213D5F, #213D5F)', textAlign:'center', border:0,borderRadius:'16px', width:'50%', marginBottom:'10px' }}  onClick={login_call_fn} >Login</Button>
                                </Col>
                            </Row>
                        </Form>

                        {/* {!org_key?(<Row>
                            <Col>
                                <Button className='btn_default' onClick={()=>{set_login_modal_type('new_to_inf');set_login_state('signup')}} style={{ margin:'25px 0px 0px 0px', width:'auto', backgroundColor:'white', color:'#333', border:'none',fontSize:'14px'}}  type="button">New to Infurnia? Sign Up</Button>
                            </Col>
                        </Row>):''} */}
                        
                    </div>
                    {!org_key?(<Row className={login_modal_type==='normal'?'d-block':'d-none'} style={{marginTop: '25px'}}>
                        <Col>
                            <span className='light_text' style={{ width:'auto', backgroundColor:'white', border:'none',fontSize:'14px'}}>New to Infurnia? </span>
                            <span onClick={()=>{set_login_modal_type('new_to_inf');set_login_state('signup')}} style={{ cursor:'pointer', width:'auto', backgroundColor:'white', color:'#333', border:'none',fontSize:'14px', textDecoration:'underline', fontStyle:'italic', color:'rgba(0, 120, 255, 1)'}}>Signup</span>
                        </Col>
                    </Row>):''}
                    
                    <div style = {{ display: login_modal_type==='new_to_inf'? 'flex':'none', width:'100%', height:'100%', flexWrap:'wrap', alignItems:'center'}}>
                        
                        {/* <Row>
                            <Col>
                                <Button className='btn_login btn_google' style={{margin:'15px 0px', backgroundColor:'#dd4b39', border:0, fontWeight:'bold'}} onClick={() =>login_pop_up('google')} type="button" >Sign up with Google </Button>
                            </Col>
                        </Row> */}
                        {/* <Row>
                            <Col>
                                <Button className='btn_google' style={{margin:'0px 0px', backgroundColor:'white', border:0, fontWeight:'bold', width:'60px', height:'60px',boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)', borderRadius:'6px', color:'red', fontSize:'28px', padding:'15px'}} type="button" onClick={() =>login_pop_up('google')} > <img className='googleLoginLogo' src='../../GoogleLogo.svg'></img> </Button>
                            </Col>
                        </Row> */}
                        {/* <Row>
                            <Col>    
                                <Button className='btn_login btn_linkedin' style={{margin:'15px 0px', backgroundColor:'#007bb6', border:0, fontWeight:'bold'}} type="button" >Sign up with Linkedin</Button>
                            </Col>
                        </Row> */}
                        {/* <Row>
                            <Col>
                                <Button className='btn_login' onClick={() =>set_login_modal_type('with_email')} style={{ margin:'15px 0px',padding:'5px', width:'100%', backgroundColor:'#008B8B', border:0, fontWeight:'bold'}}  type="button">Sign up with Email</Button>
                            </Col>
                        </Row> */}
                        
                        
                        {/* <div className='col-lg-6 col-12' style={{alignItems:'center', justifyContent:'center', display:'flex', flexDirection:'column', padding:'20px'}}>
                            <div style={{padding:'40px 40px', border:'1px solid #B8BFCA', borderRadius:'6px', cursor:'pointer', width:'350px', height:'350px'}} onClick={() => {set_account_type('professional'); set_login_modal_type('with_email')}}>
                                <Row>
                                    <Col>
                                        <div style={{fontWeight:'500'}}>I am an individual</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <img height='250px' src='../../Final Freelancer.png'></img>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className='col-lg-6 col-12' style={{alignItems:'center', justifyContent:'center', display:'flex', flexDirection:'column', padding:'20px'}}>
                            <div style={{padding:'40px 40px', border:'1px solid #B8BFCA', borderRadius:'6px', cursor:'pointer', width:'350px', height:'350px'}} onClick={() => {set_account_type('business'); set_login_modal_type('with_email')}}>
                                <Row>
                                    <Col>
                                        <div style={{fontWeight:'500'}}>Design studios or SMEs!</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <img height='250px' src='../../Final Studio.png'></img>
                                    </Col>
                                </Row>
                            </div>
                        </div> */}
                        <div style={{width:'100%'}}>
                        <div className='col-12' style={{alignItems:'center', color: 'rgba(103, 104, 120, 1)', fontSize:'20px', marginBottom:'20px'}}>
                            <span>Delighted to have you here. Who are we onboarding today </span>
                            <img style={{paddingLeft:'5px'}} src='../../smile 1.svg'></img>
                        </div>
                        <div className='col-12' style={{alignItems:'center', justifyContent:'space-evenly', display:'flex', flexDirection:'row', padding:'20px', flexWrap:'wrap'}}>
                            <div className='scaleonhover' style={{padding:'40px 40px', border:'1px solid rgba(230, 233, 238, 1)', borderRadius:'6px', cursor:'pointer', width:'350px', height:'370px', marginBottom:'20px', boxShadow:'0px 4px 16px rgba(0, 0, 0, 0.05)'}} onClick={() => {set_account_type('professional'); if(is_user_loaded){set_login_modal_type('store_setup')}else{set_login_modal_type('with_email')}}}>
                                <Row>
                                    <Col>
                                        <div style={{fontWeight:'500', fontSize:'20px'}}>I am a Freelancer</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div style={{fontStyle:'italic', color: 'rgba(167, 168, 178, 1)', marginBottom:'10px', fontSize:'12px'}}>Professional License</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <img height='225px' src='../../Final Freelancer.png'></img>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div><span style={{fontSize:'14px', backgroundColor:'#F7F7F7', padding:'6px 12px'}}>Free Account </span></div>
                                    </Col>
                                </Row>
                            </div>

                            <div className='scaleonhover' style={{padding:'40px 40px', border:'1px solid rgba(230, 233, 238, 1)', borderRadius:'6px', cursor:'pointer', width:'350px', height:'370px', marginBottom:'20px', boxShadow:'0px 4px 16px rgba(0, 0, 0, 0.05)'}} onClick={() => {set_account_type('business'); if(is_user_loaded){set_login_modal_type('store_setup')}else{set_login_modal_type('with_email')}}}>
                                <Row>
                                    <Col>
                                        <div style={{fontWeight:'500', fontSize:'20px'}}>Design studios or SMEs!</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div style={{fontStyle:'italic', color: 'rgba(167, 168, 178, 1)', marginBottom:'10px', fontSize:'12px'}}>Studio License</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <img height='225px' src='../../Final Studio.png'></img>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div><span style={{fontSize:'14px', backgroundColor:'#F7F7F7', padding:'6px 12px'}}>With 7 Days Free Trial </span></div>
                                    </Col>
                                </Row>
                            </div>

                        </div>
                        <div className='col-12' style={{alignItems:'center', color: 'rgba(167, 168, 178, 1)'}}>
                            <span>If you are already a part of organization partnered with infurnia please click here to </span>
                            <span onClick={go_back_to_normal} style={{color:'rgba(0, 120, 255, 1)', fontSize:'14px', fontStyle:'italic', textDecoration:'underline'}}  type="button">Login</span>
                        </div>
                        </div>


                        {/* <Row>
                            <Col>
                                <span onClick={go_back_to_normal} style={{ padding:'5px', color:'#5092BD', fontSize:'14px',fontWeight:'bold'}}  type="button">Go back to Login</span>
                            </Col>
                        </Row> */}
                        
                    </div>
                   
                    
                    <div style = {{ display: login_modal_type==='with_email'? 'block':'none', padding:'40px 80px', fontSize:'14px', border:'1px solid rgba(230, 233, 238, 1)', borderRadius:'14px', boxShadow:'8px 8px 20px 6px rgba(184, 191, 202, 0.14)', width:'425px', maxWidth:'99%'}}>
                        
                        {/* <Row style={{paddingBottom:'20px'}}>
                            <Col xs={5} className="text-muted text-right" >
                                <span className='sign_up_text_color'>Account Type</span>
                            </Col>
                            <Col xs={7}>
                                <Input style={{fontSize:'12px'}} value={account_type} onChange={(e) => set_account_type(e.target.value)} type='select'>
                                    <option value='professional'>Professional</option>
                                    <option value='business'>Business</option>
                                </Input>
                            </Col>
                        </Row> */}
                        {/* {
                            account_type==='business'?(
                                <Row style={{paddingBottom:'20px'}}>
                                    <Col xs={5} className="text-muted text-right" >
                                        <span className='sign_up_text_color'>Store Name</span>
                                    </Col>
                                    <Col xs={7}>
                                        <Input value={store_name} onChange={(e) => set_store_name(e.target.value)} style={{fontSize:'12px'}} placeholder='Name'  type='text'/>
                                    </Col>
                                </Row>
                            ):''
                        } */}
                        {/* <Row style={{paddingBottom:'30px'}}>
                            <Col xs={5} className="text-muted text-right">
                                <span className='sign_up_text_color'>Email</span>
                            </Col>
                            <Col xs={7}>
                                <Input value={sign_up_email} onChange={(e) => set_sign_up_email(e.target.value)} style={{fontSize:'12px'}} placeholder='example@gmail.com'  type='text'/>
                            </Col>
                        </Row> */}
                        
                        <Row>
                            <Col style={{marginBottom: '30px'}} >
                                <span style={{cursor:'pointer'}} onClick={()=>set_login_modal_type('new_to_inf')}><i style={{fontSize:'20px', marginRight:'10px'}} className='fa fa-angle-left'></i></span>
                                <span style={{fontSize:'16px', color:'#435A6F', fontWeight:'400',lineHeight:'36px'}}>{account_type==='professional'?"Create your Free Account":"Create your Studio Account"}</span>
                            </Col>
                        </Row>        
                        <Row>
                            <Col style={{marginBottom:'30px', height:'40px'}}>
                                <GoogleAuth 
                                    target={target} 
                                    id='login2'
                                    google={google}
                                    visible={login_modal_type==='with_email' ? true : false}
                                    authorization_callback={authorization_callback}
                                />
                                {/* <GoogleLogin
                                    clientId="172166388248-8i8btuhr2ga4ti4eu200j9gt04ppuavp.apps.googleusercontent.com"
                                    onSuccess={responseGoogle}
                                    onFailure={responseGoogle}
                                    cookiePolicy={'single_host_origin'}
                                /> */}
                                {/* <div class="g-signin2" data-onsuccess="onSignIn"></div> */}
                                {/* <div className='btn_google g-signin2' data-onsuccess="onSignIn" style={{margin:'0px 0px', backgroundColor:'white', border:0, fontWeight:'bold', width:'60px', height:'60px',boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)', borderRadius:'6px', color:'red', fontSize:'28px', padding:'15px'}} type="button" onClick={() =>login_pop_up('google')} > <img className='googleLoginLogo' src='../../GoogleLogo.svg'></img> </div> */}
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={5} style={{marginBottom:'15px'}} >
                                <hr style={{height:'1px', borderWidth:'0px', backgroundColor:'#A7A8B3', color:'#A7A8B3'}}></hr>
                            </Col>
                            <Col xs={2} style={{marginBottom:'15px', display:'flex', alignItems:'center', justifyContent:'center'}} >
                                <span style={{color:'#A7A8B3', display:'flex', alignItems:'center', justifyContent:'center'}}> OR </span>
                            </Col>
                            <Col xs={5} style={{marginBottom:'15px'}} >
                                <hr style={{height:'1px', borderWidth:'0px', backgroundColor:'#A7A8B3', color:'#A7A8B3'}}></hr>
                            </Col>
                        </Row>    

                        {/* {!org_key?<Row>
                            <Col>    
                                <Button className='btn_login btn_linkedin' style={{margin:'15px 0px', backgroundColor:'#007bb6', border:0 , fontWeight:'bold'}} type="button" onClick={() =>login_pop_up('linkedin')} >Sign in with Linkedin</Button>
                            </Col>
                        </Row>:''} */}
                        {/* <Row>
                            <Col style={{padding: '10px'}}>
                                <span style={{fontSize:'18px'}}>or</span>
                            </Col>
                        </Row> */}
                        {/* <Row>
                            <Col style={{margin:'30px 0px'}} >
                                <span style={{fontSize:'14px', color:'#A7A8B3', lineHeight:'24px'}}>{org_key?"Login with your org credentials":"Login with your Email ID"}</span>
                            </Col>
                        </Row>     */}
                            
                        {/* <Form role="form"> */}
                            <Row>
                                <Col style={{marginBottom:"32px"}}>
                                    {/* <FormGroup style={{marginBottom:"16px"}}> */}
                                        <Input id="signup_username" className='input_login' placeholder="Email" type="text" value={sign_up_email?sign_up_email:''} onChange={(e) => set_sign_up_email(e.target.value)} />
                                    {/* </FormGroup> */}
                                </Col>  
                            </Row>
                            <Row>
                                <Col style={{marginBottom:"16px"}}>
                                    {/* <FormGroup style={{marginBottom:"0px"}}> */}
                                        <Input id="signup_password" className='input_login' placeholder="Password" type="Password" value={create_password?create_password:''}  onChange={(e) => set_create_password(e.target.value)} />
                                    {/* </FormGroup> */}
                                </Col>  
                            </Row>
                            <Row>
                                <Col style={{marginBottom:"54px"}}>
                                    {/* <FormGroup style={{marginBottom:"0px"}}> */}
                                        <Input id="signup_confirm_password" className='input_login' placeholder="Confirm Password" type="Password" value={confirm_password?confirm_password:''}  onChange={(e) => set_confirm_password(e.target.value)} />
                                    {/* </FormGroup> */}
                                </Col>  
                            </Row>
                            <Row>
                                <Col style={{marginBottom:"12px"}}>
                                    <Button  id="login_submit_signup" type="button" className=' btn_success' style={{ background:'linear-gradient(0deg, #213D5F, #213D5F)', textAlign:'center', border:0,borderRadius:'20px', width:'auto', lineHeight:'25px', paddingLeft:'32px', paddingRight:'32px' }}  onClick={onclick_proceed_for_otp} >Create Account</Button>
                                </Col>
                            </Row>
                        {/* </Form> */}
                        {/* <Row style={{paddingBottom:'20px'}}>
                            <Col style={{display:'flex', justifyContent:'flex-end', paddingRight:'0px'}}>
                                <Button className='white_button' onClick={()=>set_login_modal_type('new_to_inf')} style={{width:'100px', display:'flex', alignItems:'center', justifyContent:'center', background:'linear-gradient(0deg, #213D5F, #213D5F)'}}>Back</Button>
                            </Col>
                            <Col style={{display:'flex', justifyContent:'flex-start'}}>
                                <Button className='blue_button' onClick={onclick_proceed_for_otp} style={{width:'100px', display:'flex', alignItems:'center', justifyContent:'center', background:'linear-gradient(0deg, #213D5F, #213D5F)'}}>Proceed</Button>
                            </Col>
                        </Row> */}
                        {/* <Row>
                            <Col>
                                <span>Already a user? </span>
                                <span onClick={go_back_to_normal} style={{color:'rgba(0, 120, 255, 1)', fontSize:'14px', fontStyle:'italic', textDecoration:'underline'}}  type="button">Login</span>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col>
                                <span className='light_text' style={{fontSize:'12px'}}>By proceeding further you agree to the </span>
                                <div className='light_text' style={{fontSize:'12px', color:'rgba(0, 120, 255, 1)' }}> <a target="_blank" href="https://www.infurnia.com/terms-and-conditions/"> terms and conditions </a></div>
                            </Col>
                        </Row>
                    </div>
                    <Row className={login_modal_type==='with_email'?'d-block':'d-none'} style={{marginTop: '25px'}}>
                        <Col>
                            <span className='light_text' style={{fontSize:'14px'}}>Already a user? </span>
                            <span onClick={go_back_to_normal} style={{color:'rgba(0, 120, 255, 1)', fontSize:'14px', fontStyle:'italic', textDecoration:'underline'}}  type="button">Login</span>
                        </Col>
                    </Row>

                    <div style = {{ display: login_modal_type==='already_signed_user'? 'block':'none', padding:'40px 25px 30px'}}>
                        
                        <Row style={{paddingBottom:'20px'}}>
                            <Col xs={5} className="text-muted text-right" >
                                <span className='sign_up_text_color'>Account Type</span>
                            </Col>
                            <Col xs={7}>
                                <Input style={{fontSize:'12px'}} value={account_type} onChange={(e) => set_account_type(e.target.value)}   type='select'>
                                    <option value='professional'>Professional</option>
                                    <option value='business'>Business</option>
                                </Input>
                            </Col>
                        </Row>
                        {
                            account_type==='business'?(
                                <Row style={{paddingBottom:'20px'}}>
                                    <Col xs={5} className="text-muted text-right" >
                                        <span className='sign_up_text_color'>Store Name</span>
                                    </Col>
                                    <Col xs={7}>
                                        <Input value={store_name} onChange={(e) => set_store_name(e.target.value)} style={{fontSize:'12px'}} placeholder='Name'  type='text'/>
                                    </Col>
                                </Row>
                            ):''
                        }
                        <Row style={{padding:'10px 0px'}}>
                            <Col className="text-center" style={{display:'flex', justifyContent:'center', alignItems:'center', marginBottom:'5px'}}>
                                <span style={{display:'flex', justifyContent:'center', alignItems:'center', marginBottom:'5px'}}><Input value={terms_and_conditions} onChange={() => set_terms_and_conditions(!terms_and_conditions)} type='checkbox'/></span>
                                <span style={{fontSize:'10px'}}>I agree to the <a target="blank" href="https://www.infurnia.com/terms-and-conditions/">terms and conditions</a> </span>
                            </Col>
                        </Row>
                        <Row style={{paddingBottom:'20px'}}>   
                            <Col style={{display:'flex', justifyContent:'center'}}>
                                <Button onClick={is_valid_otp} className='blue_button' style={{ display:'flex', alignItems:'center', justifyContent:'center', backgroundColor:'white'}}>Create Account</Button>
                            </Col>
                        </Row>
                        
                    </div>

                    <div style = {{ display: login_modal_type==='password'? 'block':'none', padding:'40px 80px', fontSize:'14px', border:'1px solid rgba(230, 233, 238, 1)', borderRadius:'14px', boxShadow:'8px 8px 20px 6px rgba(184, 191, 202, 0.14)', width:'425px', maxWidth:'99%'}}>
                        
                        {/* <Row style={{paddingBottom:'20px'}}>
                            <Col xs={5} className="text-muted text-right">
                                <span className='sign_up_text_color'>Password</span>
                            </Col>
                            <Col xs={7}>
                                <Input value={create_password} onChange={(e) => set_create_password(e.target.value)} style={{fontSize:'12px'}} placeholder='Password'  type='password'/>
                            </Col>
                        </Row> */}
                        {/* <Row style={{paddingBottom:'20px'}}>
                            <Col xs={5} className="text-muted text-right">
                                <span className='sign_up_text_color'>Re Password</span>
                            </Col>
                            <Col xs={7}>
                                <Input value={confirm_password} onChange={(e) => set_confirm_password(e.target.value)} style={{fontSize:'12px'}} placeholder='Confirm Password'  type='password'/>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col style={{marginBottom: '30px'}} >
                                <span style={{cursor:'pointer'}} onClick={()=>set_login_modal_type('with_email')}><i style={{fontSize:'20px', marginRight:'10px'}} className='fa fa-angle-left'></i></span>
                                <span style={{fontSize:'18px', color:'#435A6F', fontWeight:'400',lineHeight:'36px'}}>Verify your Email Address</span>
                            </Col>
                        </Row>
                        <div style={{backgroundColor:'inherit', textAlign:'center', marginBottom:'40px'}}> <img  height='56px' src='../../email 1.svg'/> </div>
                        <Row>
                            <Col style={{marginBottom: '30px'}} >
                                <div style={{fontSize:'14px', textAlign:'left'}}>Please enter the verification code sent to {sign_up_email}</div>
                            </Col>
                        </Row>
                        <Row style={{paddingBottom:'0px'}}>
                            {/* <Col xs={5} className="text-muted text-right">
                                <span className='sign_up_text_color'>OTP</span>
                            </Col> */}
                            <Col>
                                <Input className='input_login' value={confirm_otp} onChange={(e) => set_confirm_otp(e.target.value)} style={{fontSize:'16px', height:'50px'}} placeholder='Enter OTP'  type='text'/>
                            </Col>
                        </Row>
                        <Row style={{padding:'8px 0px', fontSize:'12px', textAlign:'left', marginBottom:'30px'}}>
                            <Col>
                                <span>Didn't get the code? </span>
                                <span onClick={onclick_proceed_for_otp} style={{ cursor:'pointer', width:'auto', backgroundColor:'white', color:'#333', border:'none',fontSize:'12px', textDecoration:'underline', fontStyle:'italic', color:'rgba(0, 120, 255, 1)'}}  type="button">Resend Code</span>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col className="text-center" style={{display:'flex', justifyContent:'center', alignItems:'center', marginBottom:'20px'}}>
                                <span style={{display:'flex', justifyContent:'center', alignItems:'center', marginBottom:'5px', marginRight:'5px'}}><Input value={terms_and_conditions} onChange={() => set_terms_and_conditions(!terms_and_conditions)} type='checkbox'/></span>
                                <span style={{fontSize:'12px'}}>I agree to the <a target="blank" href="https://www.infurnia.com/terms-and-conditions/">terms and conditions</a></span>
                            </Col>
                        </Row> */}
                        {/* <Row style={{paddingBottom:'20px'}}>
                            <Col style={{display:'flex', justifyContent:'flex-end', paddingRight:'0px'}}>
                                <Button className='white_button' onClick={()=>set_login_modal_type('with_email')} style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', backgroundColor:'white'}}>Back</Button>
                            </Col>
                            <Col style={{display:'flex', justifyContent:'flex-start'}}>
                                <Button onClick={user_validation_submit} className='blue_button' style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', backgroundColor:'white'}}>Create Account</Button>
                            </Col>
                        </Row> */}
                        
                        <Row>
                            <Col style={{marginBottom:"20px"}}>
                                <Button  id="login_submit_otp" type="button" className=' btn_success' style={{ background:'linear-gradient(0deg, #213D5F, #213D5F)', textAlign:'center', border:0,borderRadius:'20px', width:'auto', lineHeight:'25px', paddingLeft:'32px', paddingRight:'32px' }}  onClick={is_valid_otp} >Submit</Button>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                                <span>Already a user? </span>
                                <span onClick={go_back_to_normal} style={{color:'rgba(0, 120, 255, 1)', fontSize:'14px', fontStyle:'italic', textDecoration:'underline'}}  type="button">Login</span>
                            </Col>
                        </Row> */}
{/* 
                        <Row>
                            <Col>
                                <span onClick={go_back_to_normal} style={{ marginBottom:'5px',padding:'5px',color:'#5092BD', fontSize:'14px', fontWeight:'bold'}}  type="button">Go back to Login</span>
                            </Col>
                        </Row> */}
                        
                    </div>
                    <Row className={login_modal_type==='password'?'d-block':'d-none'} style={{marginTop: '25px'}}>
                        <Col>
                            <span className='light_text' style={{fontSize:'14px'}}>Already a user? </span>
                            <span onClick={go_back_to_normal} style={{color:'rgba(0, 120, 255, 1)', fontSize:'14px', fontStyle:'italic', textDecoration:'underline'}}  type="button">Login</span>
                        </Col>
                    </Row>


                    <div style = {{ display: login_modal_type==='store_setup'? 'block':'none', padding:'80px 80px 60px 80px', fontSize:'14px', border:'1px solid rgba(230, 233, 238, 1)', borderRadius:'14px', boxShadow:'8px 8px 20px 6px rgba(184, 191, 202, 0.14)', width:'425px', maxWidth:'99%'}}>
                        {
                            account_type==='business'?(
                                <React.Fragment>
                                    <Row>
                                        <Col style={{marginBottom:'8px'}}>
                                            <div className='light_text' style={{textAlign:'left'}}>Organization Name</div>
                                        </Col>
                                    </Row>
                                    <Row style={{paddingBottom:'30px'}}>
                                        {/* <Col>
                                            <span>Store Name</span>
                                        </Col> */}
                                        <Col>
                                            <Input value={store_name} onChange={(e) => set_store_name(e.target.value)} style={{width:'100%',height:'42px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', borderRadius: '3px'}} placeholder='Name'  type='text'/>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            ):''
                        }
                    <Row>
                        <Col style={{marginBottom:'8px'}}>
                            <div className='light_text' style={{textAlign:'left'}}>Country</div>
                        </Col>
                    </Row>
                    <Row style={{marginBottom:'40px'}}>
                        {/* <Col>
                            <span>Country</span>
                        </Col> */}
                        <Col>
                            <Input className='country_list' value ={store_country} onChange={(e) => set_store_country(e.target.value)} style={{width:'100%',height:'42px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', borderRadius: '3px'}} placeholder="Country" type="select">
                                {
                                    Country_list.map((country, idx) => (
                                        <option key={idx} value={country.code}>{country.name}</option>
                                    ))
                                }
                            </Input>
                            {/* <Input value={store_country} onChange={(e) => set_store_name(e.target.value)} style={{fontSize:'12px'}} placeholder='Name'  type='text'/> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{}}>
                            <Button  id="login_submit_store_setup" type="button" className=' btn_success' style={{ background:'linear-gradient(0deg, #213D5F, #213D5F)', textAlign:'center', border:0,borderRadius:'28px', width:'auto', lineHeight:'36px', paddingLeft:'32px', paddingRight:'32px' }}  onClick={user_validation_submit_no_store} >Start Designing</Button>
                        </Col>
                    </Row>
                    </div>
                {/* </Card> */}
            </div>
            </div>
        </div>    
    );
}

export default Sign_up;

